body {
  margin: 0;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
  -webkit-appearance: none;
  margin: 0;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-quill > div {
  border: 1px solid #d32f2f !important;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ql-editor {
  height: 300px;
}

.draft-by-me .ql-editor {
  height: 200px;
}

.draft-by-me .ql-toolbar {
  height: 40px;
}

.subTableCells {
  background-color: #5ba3c9;
  font-size: 12px;
  padding: 12px 8px;
}
